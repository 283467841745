/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
@import './base/bootstrap-extended.scss';

body.modal-open {
  overflow: hidden;
}

h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 26px;
  color: #373737;
}

// Menu items
.vertical-layout.vertical-menu-modern .main-menu .navigation li a {
  padding: 8px 14px 8px 14px;
}

.navigation li {
  margin-bottom: 6px;
}

// Navbar
.navbar-floating .navbar-container:not(.main-menu-content) {
  padding: 0;
}

.header-navbar.floating-nav {
 // margin-top: 8px;
 // margin-bottom: 8px;
 margin: 0;
 width: calc(100% - 260px);
 background-color: rgba(255,255,255,0.5);
 backdrop-filter: blur(16px);
 border-bottom: 1px solid #ebe9f1;
 border-radius: 0;
}

@media (max-width: 1200px) {
 .header-navbar.floating-nav {
   width: 100%;
 }
}


.navbar-container {
  margin: 20px !important;
}

.navbar-floating .header-navbar-shadow {
  height: 86px;
}

// Main content
.content.app-content {
  padding-top: 78px !important;
}

// Palette
.btn-primary {
  box-shadow: none !important;
}

.btn-outline-flat-primary {
  transition: background-color 0.1s ease;
  &:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }
}


.btn-outline-primary {
  border: none !important;
  color: #000;
}

// Calendar Tabs
.fc .fc-toolbar .fc-button-group .fc-button-primary:not(.fc-prev-button):not(.fc-next-button).fc-button-active {
  background-color: blue !important;
}

// Sidebar
.main-menu.menu-light .navigation > li.active > a {
  background: rgba(0, 0, 0, 0.04);
  color: #373737;
  font-weight: 600;
}

// Dropdown
.dropdown-menu {
  padding: 4px !important;
}

.dropdown-menu .dropdown-item {
  border-radius: 4px;
}

.btn-outline-primary:not(:disabled):not(.disabled).active {
  background-color: rgba(0,0,0,0.05);
}

.select__menu {
  padding-left: 4px;
  padding-right: 4px;
}

.react-select__option,
.select__option {
  margin-bottom: 4px !important;
  border-radius: 4px !important;
}

.fc-timegrid-slot:hover {
  background-color: rgba(0, 0, 0, 0.01) !important;
}

form > .mb-3 {
  margin-bottom: 1rem !important;
}

.btn-group {
  border-radius: 8px;
  border: 1px solid #ebe9f1;
}

#calendar-container {
  padding-left: 0 !important;
}

.rounded-pill {
  background-color: black !important;
}

// Full Calendar overrides

.fc-col-header-cell-cushion {
  padding: 6px 4px !important;
}

.fc-col-header-cell, .fc-timegrid-axis {
  background-color: rgba(0,0,0,0.015);
}

// Side modals

.modal-slide-in .modal-dialog {
  margin: 12px !important;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: -12px 4px 40px 0 rgba(0,0,0,0.10);
  border-radius: 16px;
  background: linear-gradient(0deg, #fafafa, white);
}

.modal-header {
  bottom: 1px solid #ebe9f1;
}

.modal-backdrop.show {
    opacity: 0.2;
}

// Cards
.card {
  box-shadow: none !important;
}

.pagination {
  margin-bottom: 0 !important;
  margin-top: 24px;
}

// Fields
.fields .card {
  border: 1px solid #ebe9f1;
  margin-bottom: 12px !important;
}

// Calendar Events
.fc-timegrid-event-harness > .fc-timegrid-event {
  top: 2px !important;
  bottom: 1px !important;
}

.modal-title {
  font-size: 20px;
  font-weight: 600;
  color: #343434;
}

.sidebar-wrapper .btn-group {
  overflow: visible !important;
}