@import '../../../variables/_variables.scss';

.select__multi-value {
  background-color: $primary;
}

.select__option--is-disabled.select__option--is-focused {
  background-color: $white !important;
  color: #ededed !important;
}

.readonly .select__control.select__control--is-disabled {
  border: none;
  background-color: rgba(0, 0, 0, 0.04);
  .select__indicators {
    display: none;
  }
}

.select__control {
  border-color: #d8d6de;
}
