.fallback-spinner {
  .loading {
    .effect-1,
    .effect-2,
    .effect-3 {
      border-left: 3px solid rgb(52, 52, 52);
    }
  }
}
.fallback-logo {
  width: 180px;
  height: 180px;
}
