@import './forms/form-control';
@import './forms/form-check';
@import './forms/input-group';

form {
  flex: 1;
}
// placeholder specific scss
.form-control {
  &--view {
    border: none;
  }
}

.custom-switch {
  z-index: 0; // Otherwise react select goes beneath it
}

.input-group-text.disabled {
  background-color: #e9ecef;
}
