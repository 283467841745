.form-check.form-switch.readonly {
  padding-left: 0;
  input {
    display: none;
  }
  label {
    opacity: unset;
    color: black;
  }
}
