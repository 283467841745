//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================
// !!!!!! For dark theme add theme-dark. for example $theme-dark-primary and use them below .dark-layout class
$primary: rgb(52, 52, 52); // Changing primary color
$color-dark: #4b4b4b;
$success: #28c76f;
$white: #ffffff;

$font-family-sans-serif: 'Roboto Flex', sans-serif;
$font-family-monospace: 'Roboto Flex', sans-serif;

$context-oriented-category-friendly-primary: #1257e0;
$context-oriented-category-friendly-secondary: #e0e9fb;
$context-oriented-category-training-primary: #c14c32;
$context-oriented-category-training-secondary: #f6e3d5;
$context-oriented-category-maintenance-primary: #50002b;
$context-oriented-category-maintenance-secondary: #ebd9df;
$context-oriented-category-tournament-primary: #690f80;
$context-oriented-category-tournament-secondary: #e8e3ff;
$context-oriented-category-other-primary: #000000;
$context-oriented-category-other-secondary: #ffffff;

$context-oriented-content-base-content-5: rgba(0, 0, 0, 0.05);
$context-oriented-content-base-content-10: rgba(0, 0, 0, 0.1);
